import React, { useState } from 'react';
import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
    Image,
    BrokenImage,
    Autorenew,
    Settings as SettingsIcon,
    FastForward,
    CheckBox as CheckBoxIcon,
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
    Schedule as ScheduleIcon, // New icon for scheduleAll
    Cancel as CancelIcon, // Use Cancel icon instead
} from '@mui/icons-material';
import { IconButton, Paper, CircularProgress, Tooltip } from '@mui/material';

import { Link } from 'react-router-dom';
import { useSettings } from './atoms/SettingsAtom';
import { usePlanning } from './hooks/usePlanning';
import { useTasks } from './providers/TasksProvider';
import KickTheCanDialog from './components/KickTheCanDialog';

export const ActionBar = () => {
    const { settings, toggleSetting } = useSettings();
    const { instantPlan, isPlanning, kickTheCan, scheduleAll, unScheduleAll } = usePlanning();
    // const { createRandomTasks } = useTasks();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        kickTheCan();
        handleClose();
    };

    return (
        <Paper elevation={3} className="flex items-center p-2 bg-white rounded-lg">
            <div className="flex items-center space-x-4">
                <Tooltip title="Kick all tasks into the future" aria-label="Kick all tasks into the future">
                    <button onClick={handleOpen}>
                        <FastForward style={{ fontSize: '2rem' }} />
                    </button>
                </Tooltip>

                <div className="flex items-center ml-8 space-x-4">
                    <Tooltip title="Toggle background" aria-label="Toggle background">
                        <button onClick={() => toggleSetting('showBackground')}>
                            {settings.showBackground ? (
                                <Image style={{ fontSize: '2rem' }} />
                            ) : (
                                <BrokenImage style={{ fontSize: '2rem' }} />
                            )}
                        </button>
                    </Tooltip>

                    <Tooltip title="Toggle future tasks visibility" aria-label="Toggle future tasks visibility">
                        <IconButton onClick={() => toggleSetting('showFuture')}>
                            {settings.showFuture ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Toggle completed tasks visibility" aria-label="Toggle completed tasks visibility">
                        <IconButton onClick={() => toggleSetting('showCompleted')}>
                            {settings.showCompleted ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Fetch new plan" aria-label="Fetch new plan">
                        <button onClick={instantPlan}>
                            {isPlanning ? (
                                <div style={{ paddingTop: '7px', marginRight: '5px' }}>
                                    <CircularProgress size={27} style={{ color: 'green' }} />
                                </div>
                            ) : (
                                <Autorenew style={{ fontSize: '2rem' }} />
                            )}
                        </button>
                    </Tooltip>

                    <Tooltip title="Schedule all tasks" aria-label="Schedule all tasks">
                        <button onClick={scheduleAll}>
                            <ScheduleIcon style={{ fontSize: '2rem' }} />
                        </button>
                    </Tooltip>

                    <Tooltip title="Unschedule all tasks" aria-label="Unschedule all tasks">
                        <button onClick={unScheduleAll}>
                            <CancelIcon style={{ fontSize: '2rem' }} />
                        </button>
                    </Tooltip>

                    <Tooltip title="Settings" aria-label="Settings">
                        <Link to="/settings" className="p-0 ml-4 rounded-full hover:bg-gray-200">
                            <SettingsIcon className="text-gray-400" style={{ fontSize: '2rem' }} />
                        </Link>
                    </Tooltip>

                    {/* <Tooltip title="Generate Fake Tasks" aria-label="Generate Fake Tasks">
                        <Button onClick={createRandomTasks} variant="contained" color="primary">
                            Generate Fake Tasks
                        </Button>
                    </Tooltip> */}
                </div>
            </div>

            <KickTheCanDialog isOpen={open} onClose={handleClose} onConfirm={handleConfirm} />
        </Paper>
    );
};
