import { Route, Routes, useLocation } from 'react-router-dom';
import React, { lazy, Suspense, useEffect } from 'react';
import HomeLoading from './components/HomeLoading';
import MagicLogin from './components/auth/MagicLogin';
import { isAuthenticatedAtom } from './atoms/authAtoms';
import { useAtom } from 'jotai';

const preloadComponent = (factory) => {
    factory();
};

const lazyWithPreload = (factory) => {
    const Component = lazy(factory);
    Component.preload = () => preloadComponent(factory);
    return Component;
};

// Preloaded components
const RequireAuth = lazyWithPreload(() => import('./components/auth/RequireAuth'));
const Home = lazyWithPreload(() => import('./components/home/Home'));
const MynCalendar = lazyWithPreload(() => import('./components/calendar/MynCalendar'));
const Settings = lazyWithPreload(() => import('./components/settings/Settings'));
const Planner = lazyWithPreload(() => import('./components/Planner'));

// Regular lazy-loaded components
const GettingStarted = lazy(() => import('./components/auth/GettingStarted'));
const MsCallback = lazy(() => import('./components/calendar/MsCallback'));
const ScheduleEditor = lazy(() => import('./components/settings/ScheduleEditor'));
const Version = lazy(() => import('./Version'));
const TimeManagementSurvey = lazy(() => import('./components/survey/TimeManagementSurvey'));
const Stats = lazy(() => import('./components/stats/Stats'));

const preloadPriorityComponents = () => {
    RequireAuth.preload();
    Home.preload();
    MynCalendar.preload();
    Settings.preload();
    Planner.preload();
};

function AppRoutes({ openTaskModal }) {
    const [isAuthenticated] = useAtom(isAuthenticatedAtom);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const exchangeToken = searchParams.get('token');
    const directToken = searchParams.get('directtoken');

    useEffect(() => {
        // Preload priority components in the background
        preloadPriorityComponents();
    }, []);

    if (!isAuthenticated && !exchangeToken && !directToken) {
        return (
            <Suspense fallback={<HomeLoading />}>
                <Routes>
                    <Route path="*" element={<GettingStarted />} />
                </Routes>
            </Suspense>
        );
    }

    return (
        <Suspense fallback={<HomeLoading />}>
            <Routes>
                <Route path="/gettingstarted" element={<GettingStarted />} />
                <Route path="/magic-login" element={<MagicLogin />} />
                <Route element={<RequireAuth redirectPath="/GettingStarted" />}>
                    <Route path="/" element={<Home />} />
                    <Route path="home" element={<Home />} />
                    <Route path="mscallback" element={<MsCallback />} />
                    <Route path="stats" element={<Stats />} />
                    <Route path="settings" element={<Settings success="false" />} />
                    <Route path="cancel" element={<Settings success="false" />} />
                    <Route path="success" element={<Settings success="true" />} />
                    <Route path="planner" element={<Planner />} />
                    <Route path="calendar" element={<MynCalendar />} />
                    <Route path="version" element={<Version />} />
                    <Route path="time-mangement-survey" element={<TimeManagementSurvey />} />
                    <Route path="/settings/:tab?" element={<Settings />} />
                </Route>
                <Route
                    path="*"
                    element={
                        <main style={{ padding: '1rem' }}>
                            <p>There's nothing here!</p>
                        </main>
                    }
                />
            </Routes>
        </Suspense>
    );
}

export default AppRoutes;
